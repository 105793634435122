<template>
  <div id="app">
    <Kasir />
  </div>
</template>

<script>
import Kasir from './views/KasirPage.vue';

export default {
  components: {
    Kasir
  }
};
</script>

<style>
/* Global styles */
</style>
