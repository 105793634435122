<template>
  <div class="container mt-4">
    <h3>No. Transaksi #{{ this.$route.params.notrk }}</h3>
    <div class="row">
      <div class="col-md-6" style="height: 95vh; overflow: auto">
        <ProductList @add-to-cart="addToCart" />
      </div>

      <div class="col-md-6">
        <Cart 
          :cartItems="cartItems" 
          :totalPrice="totalPrice" 
          @remove-item="removeItem" 
          @update-cart="handleUpdateCart" 
        />
      </div>
    </div>
  </div>
</template>

<script>
import ProductList from '@/components/ProductList.vue';
import Cart from '@/components/CartPage.vue';

export default {
  props: ['notrk'],
  components: {
    ProductList,
    Cart,
  },
  data() {
    return {
      cartItems: [], // Data keranjang belanja
    };
  },
  computed: {
    totalPrice() {
      // Menghitung total harga seluruh item di keranjang
      return this.cartItems.reduce(
        (total, item) => total + item.harga * item.quantity,
        0
      );
    },
  },
  methods: {
    addToCart(product) {
      // Pengecekan menggunakan kode dan satuan untuk memastikan produk dengan satuan berbeda tidak digabungkan
      const existingItem = this.cartItems.find(
        (item) => item.kode === product.kode && item.satuan === product.satuan
      );

      if (existingItem) {
        // Jika barang dengan kode dan satuan yang sama sudah ada, tambahkan sesuai quantity yang diinput
        existingItem.quantity += product.quantity;
      } else {
        // Jika barang dengan kode dan satuan berbeda, tambahkan sebagai item baru dengan quantity sesuai input
        this.cartItems.push({ ...product });
      }
    },
    removeItem(kode) {
      // Menghapus item dari keranjang berdasarkan kode produk
      this.cartItems = this.cartItems.filter((item) => item.kode !== kode);
    },
    handleUpdateCart(updatedCartItems) {
      // Perbarui data keranjang berdasarkan perubahan di komponen anak
      this.cartItems = updatedCartItems;
    },
  },
};
</script>


<style scoped>
/* Custom styles for cashier page */
</style>
