import { createApp } from 'vue';
import App from './App.vue';
import router from './router'; // Import Vue Router
import '@fortawesome/fontawesome-free/css/all.css';

// Import Bootstrap and its required dependencies
import 'bootstrap/dist/css/bootstrap.css';
import 'bootstrap';

// Create the Vue app
const app = createApp(App);

// Use Vue Router
app.use(router);

// Mount the app to the DOM
app.mount('#app');
