<template>
  <div class="product-list">
    <div class="row g-1">
      <div class="col-lg-6">
        <label for="">Cari Produk</label>
        <div class="input-group mb-1">
          <input
            type="text"
            class="form-control form-control-sm"
            v-model="searchQuery"
            placeholder="Cari produk..."
            @keyup="cariproduk"
          />
        </div>
      </div>
      <div class="col-lg-6">
        <label for="">Pelanggan</label>
        <select
          class="select2 form-control"
          ref="customerSelect"
          v-model="idcust"
          @change="cariproduk"
        >
        </select>
      </div>
    </div>
    <div v-for="product in products" :key="product.id" class="card mb-3">
      <div class="card-body p-2">
        <div class="row pb-1">
          <div class="col-md-8">
            <b class="card-title pt-1 pb-1">{{ product.nama }}</b><br>
            <p class="card-text">
              Stok : {{ product.stok }}
            </p>
          </div>
          <div class="col-md-4">
            
              <label>Jumlah</label>
              <input
                type="number"
                class="form-control"
                v-model.number="product.quantity"
                min="1"
                placeholder="Masukkan jumlah"
              />
          </div>
        </div>
        
        
        <div class="btn-group w-100">
          <button
            @click="selectPrice(product, product.induk, product.harga1)"
            class="btn btn-primary btn-sm"
          >
            Harga per {{ product.induk }}<br>
            {{ formatCurrency(product.harga1) }}
          </button>
          <button
            @click="selectPrice(product, product.turunan, product.harga2)"
            class="btn btn-success btn-sm"
          >
            Harga per {{ product.turunan }}<br>
            {{ formatCurrency(product.harga2) }}
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from 'axios';
import 'select2/dist/css/select2.css';
import $ from 'jquery';
import 'select2';

export default {
  data() {
    return {
      searchQuery: '',
      products: [],
      customers: [],
      idcust: '1',
    };
  },
  mounted() {
    this.initializeSelect2();
    this.getCustomers();
  },
  methods: {
    async cariproduk() {
      try {
        const response = await axios.get('https://km99.web.id/api/produk', {
          params: {
            search: this.searchQuery,
            idcust: this.idcust
          },
        });
        this.products = response.data.map(product => ({
          ...product,
          quantity: 1 // Set default quantity to 1
        }));
      } catch (error) {
        console.error('Gagal mengambil produk:', error);
      }
    },
    async getCustomers() {
      try {
        const response = await axios.get('https://km99.web.id/api/pelanggan');
        this.customers = response.data;
        this.initializeSelect2();
      } catch (error) {
        console.error('Gagal mengambil pelanggan:', error);
      }
    },
    initializeSelect2() {
      $(this.$refs.customerSelect).select2({
        data: this.customers.map(customer => ({
          id: customer.id,
          text: `${customer.kodemember} - ${customer.nama}`
        }))
      });
      $(this.$refs.customerSelect).on('change', () => {
        this.idcust = $(this.$refs.customerSelect).val();
        this.cariproduk();
      });
    },
    selectPrice(product, satuan, harga) {
    // Pastikan jumlah yang dipilih adalah sesuai
      const selectedQuantity = product.quantity || 1; // Default ke 1 jika jumlah tidak diisi
      
      const selectedProduct = {
        kode: product.kode,
        nama: product.nama,
        satuan,
        harga,
        quantity: selectedQuantity, // Gunakan nilai quantity yang benar
        idcust: this.idcust
      };
    
      this.addToCart(selectedProduct);
    },
    formatCurrency(value) {
      return new Intl.NumberFormat('id-ID', {
        minimumFractionDigits: 0,
        maximumFractionDigits: 0,
      }).format(value);
    },
    addToCart(product) {
      this.$emit('add-to-cart', product);
    }
  }
};
</script>

<style scoped>
/* Custom styles for product list */
</style>
