import { createRouter, createWebHistory } from 'vue-router';
import KasirPage from '@/views/KasirPage.vue'; // Pastikan Anda memiliki komponen ini

const routes = [
  {
    path: '/:notrk',
    name: 'Kasir',
    component: KasirPage,
    props: true,  // Agar parameter dikirim sebagai prop
  }
];

const router = createRouter({
  history: createWebHistory(),
  routes,
});

export default router;
